// export const myAction = async ({comit}) => {

// }


export const updateIsLoading = async ({commit}, value) => {
    commit('setIsLoading', value)
}



